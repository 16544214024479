import axios, { AxiosError } from 'axios';
import { redirect } from '@tanstack/react-router';

export type TCustomError = {
  message: string;
  code: number;
  name: string;
  payload: null;
};
export type TCustomAxiosError = {
  code: number;
  data: null;
  success: boolean;
  errors: TCustomError[];
};

export type TCustomAxiosPaginationResponse<T> = {
  code: number;
  success: boolean;
  errors: TCustomError[];
  data: T;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    offset: number;
    totalItems: number;
  };
};

export type TPagination = {
  getFirstPage: () => void;
  getNextPage: () => void;
  getPreviousPage: () => void;
  getLastPage: () => void;
  currentPage: number;
  totalPages: number;
};

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL as string,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    // TODO: Handle errors globally and token expiration
    // Handle specific error cases
    if (error.response) {
      // Handle specific error cases
      if (error?.response.status === 401) {
        // Unauthorized
        console.log('Unauthorized...');
        // TODO: check why this isn't redirecting...
        redirect({ to: '/sign-in' });
        console.log('redirect done');
      } else if (error.response.status === 404) {
        // Not found
      }
      // NOTE: Make generic axios error more readable. This is just an example, remove if unnecessary.
      return Promise.reject(error.response.data);
    }
  },
);

export { api };
