import { useState } from 'react';

import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import SvgIcon from '../SvgIcon';
import colors from '@/theme/colors';
import { FORM_FIELD_WIDTH } from '@/constants/dimensions';

interface Props {
  onClick: FlexProps['onClick'];
  iconPath: string;
  iconColor?: string;
  iconHoverColor?: string;
  tooltipLabel: string;
  tooltipBackgroundColor?: string;
}

const SvgTooltipIcon = ({
  onClick,
  iconPath,
  iconColor = colors.text.darkBlue,
  iconHoverColor = colors.extra.red,
  tooltipLabel,
  tooltipBackgroundColor = iconHoverColor,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip
      label={tooltipLabel}
      placement={'end'}
      color={'white'}
      background={tooltipBackgroundColor}
      maxWidth={FORM_FIELD_WIDTH}
      borderRadius={'8px'}
      fontWeight={600}
      padding={'8px'}
      marginLeft={'-20px'}
      // marginTop={'-5px'}
    >
      <Flex
        paddingY={'5px'}
        justifyContent={'center'}
        width={'40%'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        cursor={'pointer'}
        onClick={onClick}
      >
        <SvgIcon iconPath={iconPath} color={isHovered ? iconHoverColor : iconColor} size="18" />
      </Flex>
    </Tooltip>
  );
};

export default SvgTooltipIcon;
