import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { Box, HStack, Input } from '@chakra-ui/react';
import { createFileRoute } from '@tanstack/react-router';
import CustomButton from '@/components/atoms/CustomButton';
import { useState } from 'react';
import ActivatedUsersTable from '@/modules/user/ActivatedUsersTable';
import DeactivatedUsersTable from '@/modules/user/DeactivatedUsersTable';
import { TUserFilterOption } from '@/types/User.types';
import { USER_FILTER_BY_OPTIONS } from '@/constants/stringVars';
// import Dropdown2 from '@/components/atoms/Dropdown/Dropdown2';
import Dropdown from '@/components/atoms/Dropdown';

const Users = () => {
  const [usersSelection, setUsersSelection] = useState<'activated' | 'deactivated'>('activated');

  const [filterOption, setFilterOption] = useState<TUserFilterOption | undefined>();
  const [filterText, setFilterText] = useState('');

  return (
    <SidebarItemViewTemplate title={`${usersSelection === 'activated' ? 'Activated' : 'Deactivated'} Users`}>
      <HStack width={'90%'} marginTop={'20px'} justifyContent={'space-between'}>
        <CustomButton
          label={`See ${usersSelection === 'activated' ? 'Deactivated' : 'Activated'}`}
          onClick={() => setUsersSelection(usersSelection === 'activated' ? 'deactivated' : 'activated')}
          style={{ marginLeft: '-70px' }}
        />
        <HStack gap={'15px'}>
          {/* <Dropdown2
            label="Select filter"
            value={{ value: filterOption, label: filterOption ?? '' }}
            options={USER_FILTER_BY_OPTIONS.map((opt) => ({ label: opt, value: opt }))}
            onSelect={(newOption) => setFilterOption(newOption.value as TUserFilterOption)}
          /> */}
          <Dropdown
            title="Select filter"
            selectedOption={filterOption}
            onOptionClick={(option) => setFilterOption(option as TUserFilterOption)}
            options={USER_FILTER_BY_OPTIONS}
          />
          <Input
            width={'300px'}
            backgroundColor={'white'}
            placeholder={`Search users by ${filterOption !== undefined ? filterOption : '...'}`}
            _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </HStack>
      </HStack>
      {/* TODO: should the width of the container Box be ={'90%'} ?? */}
      <Box marginTop={'20px'} marginBottom={'80px'} width={'90%'}>
        {usersSelection === 'activated' ? (
          <ActivatedUsersTable filterOption={filterOption} filterText={filterText} />
        ) : (
          <DeactivatedUsersTable filterOption={filterOption} filterText={filterText} />
        )}
      </Box>
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/users/')({
  component: Users,
});
