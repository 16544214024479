import { useDeactivatedUsers, useUserActivation } from '@/hooks/user/users';
import React, { MouseEvent, useState } from 'react';
import UsersTable from './UsersTable';
import { TUserActivationToggle, TUserFilterOption } from '@/types/User.types';
import SvgTooltipIcon from '@/components/atoms/SvgTooltipIcon';
import svgIcons from '@/assets/svg/icons';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import colors from '@/theme/colors';

interface Props {
  filterOption?: TUserFilterOption;
  filterText: string;
}

const DeactivatedUsersTable = ({ filterOption, filterText }: Props) => {
  const { isLoading, data, pagination, refetch, isRefetching } = useDeactivatedUsers({ filterOption, filterText });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [activatingUser, setActivatingUser] = useState<TUserActivationToggle>();

  const { mutate, isPending } = useUserActivation();

  const onActivationSuccess = () => {
    setIsModalOpened(false);
    refetch();
  };

  const activateSelectedUser = () => {
    if (activatingUser) {
      mutate({ userId: activatingUser.id }, { onSuccess: onActivationSuccess });
    }
  };

  return (
    <>
      <UsersTable
        users={data}
        pagination={pagination}
        isLoading={isLoading}
        isRefetching={isRefetching}
        invalidatedUserId={activatingUser?.id}
        ActionIcon={(userToActivate: TUserActivationToggle) => (
          <SvgTooltipIcon
            iconPath={svgIcons.userActivate}
            iconHoverColor={colors.primary[500]}
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setIsModalOpened(true);
              setActivatingUser(userToActivate);
            }}
            tooltipLabel="Activate User"
          />
        )}
      />
      <ConfirmationModal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setActivatingUser(undefined);
        }}
        title="Confirm Activation"
        confirmButtonText="Activate"
        description={`This will activate user ${activatingUser?.firstName} ${activatingUser?.lastName}`}
        onConfirm={activateSelectedUser}
        confirmButtonColor="primary.500"
        confirmLoading={isPending}
      />
    </>
  );
};

export default DeactivatedUsersTable;
