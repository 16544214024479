// import { UseMutateFunction, useMutation } from '@tanstack/react-query';
// import { TCustomAxiosError } from '@/services/api';
// import useToast from '../useToast';
import { TCustomAxiosError } from '@/services/api';
import { usePagination } from '../usePagination';
import {
  activateUserPost,
  deactivateUserPost,
  getAllActivatedUsers,
  getAllDeactivatedUsers,
} from '@/services/api/requests/user';
import { TUser, TUserFilter } from '@/types/User.types';
import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import queryKeys from '@/constants/queryKeys';

const useActivatedUsers = ({ filterOption, filterText }: TUserFilter) => {
  return usePagination<TUser[], TUserFilter>({
    query: getAllActivatedUsers,
    queryKey: queryKeys.users.activatedUsers,
    queryParams: { filterOption, filterText },
  });
};

const useDeactivatedUsers = ({ filterOption, filterText }: TUserFilter) => {
  return usePagination<TUser[], TUserFilter>({
    query: getAllDeactivatedUsers,
    queryKey: queryKeys.users.deactivatedUsers,
    queryParams: { filterOption, filterText },
  });
};

const useUserActivation = (): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<AxiosResponse<TUser, any>, TCustomAxiosError, { userId: string }>;
} => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const response = await activateUserPost(userId);

      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'User successfully activated.',
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'User activation error',
        description: error?.errors[0]?.message,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users.deactivatedUsers], exact: false, stale: true });
    },
  });

  return { mutate, isPending, isError };
};

const useUserDeactivation = (): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<AxiosResponse<TUser, any>, TCustomAxiosError, { userId: string }>;
} => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ userId }: { userId: string }) => {
      const response = await deactivateUserPost(userId);

      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'User successfully deactivated.',
      });
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        status: 'error',
        title: 'User deactivation error',
        description: error?.errors[0]?.message,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.users.activatedUsers], exact: false, stale: true });
    },
  });

  return { mutate, isPending, isError };
};

export { useActivatedUsers, useDeactivatedUsers, useUserActivation, useUserDeactivation };
