import { useActivatedUsers, useUserDeactivation } from '@/hooks/user/users';
import React, { MouseEvent, useState } from 'react';
import UsersTable from './UsersTable';
import svgIcons from '@/assets/svg/icons';
import SvgTooltipIcon from '@/components/atoms/SvgTooltipIcon';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { TUserActivationToggle, TUserFilterOption } from '@/types/User.types';

interface Props {
  filterOption?: TUserFilterOption;
  filterText: string;
}

const ActivatedUsersTable = ({ filterOption, filterText }: Props) => {
  const { isLoading, data, pagination, refetch, isRefetching } = useActivatedUsers({ filterOption, filterText });
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [deactivatingUser, setDeactivatingUser] = useState<TUserActivationToggle>();

  const { mutate, isPending } = useUserDeactivation();

  const onDeactivationSuccess = () => {
    setIsModalOpened(false);
    refetch();
  };

  const deactivateSelectedUser = () => {
    if (deactivatingUser) {
      mutate({ userId: deactivatingUser.id }, { onSuccess: onDeactivationSuccess });
    }
  };

  return (
    <>
      <UsersTable
        users={data}
        pagination={pagination}
        isLoading={isLoading}
        isRefetching={isRefetching}
        invalidatedUserId={deactivatingUser?.id}
        ActionIcon={(userToDeactivate: TUserActivationToggle) => (
          <SvgTooltipIcon
            iconPath={svgIcons.userDeactivate}
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setIsModalOpened(true);
              setDeactivatingUser(userToDeactivate);
            }}
            tooltipLabel="Deactivate User"
          />
        )}
      />
      <ConfirmationModal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setDeactivatingUser(undefined);
        }}
        title="Confirm Deactivation"
        confirmButtonText="Deactivate"
        description={`This will deactivate user ${deactivatingUser?.firstName} ${deactivatingUser?.lastName}`}
        onConfirm={deactivateSelectedUser}
        confirmButtonColor="extra.red"
        confirmLoading={isPending}
      />
    </>
  );
};

export default ActivatedUsersTable;
