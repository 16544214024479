import React from 'react';
import { Input, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateOrganization } from '@/hooks/organization/organization';
import { useRouter } from '@tanstack/react-router';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import CustomButton from '@/components/atoms/CustomButton';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

const CreateOrganizationModal = ({ isOpen, onClose }: Props) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useCreateOrganization((organization) => {
    router.navigate({ to: `/${organization._id}` });

    queryClient.invalidateQueries({ queryKey: ['organization'] });
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        name: yup
          .string()
          .trim()
          .min(6, 'Organization name needs to be at least 4 characters long.')
          .required('Name is required.'),
      }),
    ),
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = ({ name }: { name: string }) => {
    mutate({ name });
  };

  return (
    <ModalContainer isOpen={isOpen} onClose={onClose} title="Create New Organization">
      <VStack>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack spacing={'20px'} width={'100%'}>
              <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
                <Input placeholder="Organization name" {...register('name')} />
                <Text color={'red.500'} fontSize={'14px'}>
                  {errors.name?.message ?? ''}
                </Text>
              </VStack>
              <CustomButton label="Create" buttonType="submit" disabled={!isValid} isLoading={isPending} />
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </ModalContainer>
  );
};

export default CreateOrganizationModal;
