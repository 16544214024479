import { Text } from '@chakra-ui/react';
import { getHighestUserRoleDisplay } from '@/utils/stringUtils';
import CompactTablePagination from '@/components/molecules/CompactTablePagination/CompactTablePagination';
import tableTheme from '@/theme/components/table';
import { TUser, TUserActivationToggle } from '@/types/User.types';

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell } from '@table-library/react-table-library/table';
import { useTheme } from '@table-library/react-table-library/theme';
import { TPagination } from '@/services/api';
import LoadingPage from '@/components/templates/LoadingPage';
import { formatDistance } from 'date-fns';
import { INCONCLUSIVE_LAST_LOGIN_TEXT } from '@/constants/stringVars';

const COLUMNS: string[] = ['First Name', 'Last Name', 'Email', 'Role', 'Organization', 'Last Sign In', 'Activation'];

interface Props {
  users: TUser[] | undefined;
  pagination: TPagination;
  isLoading: boolean;
  isRefetching: boolean;
  ActionIcon: React.FunctionComponent<TUserActivationToggle>;
  invalidatedUserId?: string;
}

const UsersTable = ({ users, pagination, isLoading, isRefetching, ActionIcon, invalidatedUserId }: Props) => {
  // const router = useRouter();
  const theme = useTheme(tableTheme);

  const data = {
    nodes:
      users?.map((user) => ({
        ...user,
        id: user.user_id,
      })) ?? [],
  };

  // const onSelectChange = (action: { payload?: { id: string } }) => {
  //   const userId = action.payload?.id;
  //   router.navigate({ to: `/users/${userId}` });
  // };

  // const select = useRowSelect(
  //   { nodes: [] },
  //   {
  //     onChange: onSelectChange,
  //   },
  //   { isCarryForward: false },
  // );

  if (isLoading) return <LoadingPage />;

  if (data.nodes.length === 0)
    return (
      <Text variant={'loraSmallTitle'} marginTop={'70px'} marginLeft={'-150px'}>
        No users
      </Text>
    );

  if (data.nodes.length > 0) {
    return (
      <>
        {/* select={select} */}
        <Table data={data} theme={theme}>
          {(tableList: any) => (
            <>
              <Header>
                <HeaderRow>
                  {COLUMNS.map((column: string) => (
                    <HeaderCell key={column}>{column}</HeaderCell>
                  ))}
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item: (typeof data.nodes)[number]) => {
                  const isInvalidatedUser = item.id === invalidatedUserId;
                  return (
                    <Row key={item.id} item={item} className={isInvalidatedUser ? 'tr-invalidated' : ''}>
                      <Cell>{item.firstName}</Cell>
                      <Cell>{item.lastName}</Cell>
                      <Cell>{item.email}</Cell>
                      <Cell>{getHighestUserRoleDisplay(item.roles)}</Cell>
                      <Cell>{item.organizationName !== '' ? item.organizationName : '/'}</Cell>
                      <Cell>
                        {item.last_login
                          ? formatDistance(new Date(item.last_login), new Date(), { addSuffix: true })
                          : INCONCLUSIVE_LAST_LOGIN_TEXT}
                      </Cell>
                      <Cell>
                        {isInvalidatedUser ? (
                          ''
                        ) : (
                          <ActionIcon id={item.id} firstName={item.firstName} lastName={item.lastName} />
                        )}
                      </Cell>
                    </Row>
                  );
                })}
              </Body>
            </>
          )}
        </Table>

        <CompactTablePagination pagination={pagination} isFetchingNextPage={isRefetching} />
      </>
    );
  }
};

export default UsersTable;
